import * as yup from "yup";

const getPersonSchema = () =>
    // TODO: Remove object can be just lastName
    yup.object().shape({
        lastName: yup.string().trim().required("Last name is required"),
    });

const getPersonSchemaNotRequired = () =>
    yup.object().shape({
        lastName: yup.string().trim(),
    });

export const projectSchema = yup.object({
    code: yup.string().required(),
    title: yup.string().required(),
    team: yup.string(),
    // TODO: make this date
    endDate: yup.date().required(),
    endDateYear: yup.number(),
    hrpPdrh: yup.string().required(),
    shrFocalPoint: getPersonSchema(),
    countryItems: yup
        .array(
            yup.object({
                country: yup.string(),
                region: yup.string(),
                roFocalPoint: getPersonSchemaNotRequired(),
                coFocalPoint: getPersonSchemaNotRequired(),
                implementationPartner: yup.string(),
                pricipalInvestigator: getPersonSchemaNotRequired(),
                fundingSource: yup.string(),
            })
        )
        .when(["scope"], {
            is: (scope: string) => {
                return scope !== "International";
            },
            then: yup.array(
                yup.object({
                    country: yup.string().required(),
                    region: yup.string().required(),
                    roFocalPoint: getPersonSchema(),
                    coFocalPoint: getPersonSchema(),
                    implementationPartner: yup.string().required(),
                    pricipalInvestigator: getPersonSchema(),
                    fundingSource: yup.string().required(),
                })
            ),
        }),
    description: yup.string().required(),
    hasCosponsorEngagement: yup.boolean().required(),
    agencies: yup
        .array(
            yup.object({
                agency: yup.string(),
                primaryCosponsorContact: yup.object({
                    lastName: yup.string().trim(),
                }),
            })
        )
        .when(["hasCosponsorEngagement"], {
            is: (hasCosponsorEngagement: boolean) => hasCosponsorEngagement,
            then: yup.array(
                yup.object({
                    agency: yup.string().required("Agency is required"),
                    primaryCosponsorContact: yup.object({
                        lastName: yup.string().trim().required("Last name is required"),
                    }),
                })
            ),
        }),
    statusOfEngagement: yup.string().when("hasCosponsorEngagement", {
        is: (hasCosponsorEngagement: boolean) => hasCosponsorEngagement,
        then: yup.string().required(),
    }),
    scope: yup.string().when("hasCosponsorEngagement", {
        is: (hasCosponsorEngagement: boolean) => hasCosponsorEngagement,
        then: yup.string().required("Scope is required"),
    }),
});

export interface IProject {
    id: string;
    code: string;
    title: string;
    team: string;
    endDate: Date;
    endDateYear: number;
    hrpPdrh: string;
    shrFocalPoint: {
        lastName: string;
    };
    countryItems: [
        {
            country: string;
            region: string;
            roFocalPoint: {
                lastName: string;
            };
            coFocalPoint: {
                lastName: string;
            };
            implementationPartner: string;
            pricipalInvestigator: {
                lastName: string;
            };
            fundingSource: string;
        }
    ];
    description: string;
    hasCosponsorEngagement: boolean;
    agencies: [
        {
            agency: string;
            primaryCosponsorContact: {
                lastName: string;
            };
        }
    ];
    statusOfEngagement: string;
    scope: string;
    countriesKeys?: string[];
    regionKeys?: string[];
    roFocalPointKeys?: string[];
    coFocalPointKeys?: string[];
    pricipalInvestigatorKeys?: string[];
    implementationPartnerKeys?: string[];
    fundingSourceKeys?: string[];
    agencyKeys?: string[];
    primaryCosponsorContactKeys?: string[];
}

export const defaultProjectValues: IProject = {
    id: "",
    code: "",
    title: "",
    team: "",
    // TODO: make this date
    endDate: new Date(),
    endDateYear: new Date().getFullYear(),
    hrpPdrh: "",
    shrFocalPoint: {
        lastName: "",
    },
    countryItems: [
        {
            country: "",
            region: "",
            roFocalPoint: {
                lastName: "",
            },
            coFocalPoint: {
                lastName: "",
            },
            implementationPartner: "",
            pricipalInvestigator: {
                lastName: "",
            },
            fundingSource: "",
        },
    ],
    description: "",
    hasCosponsorEngagement: false,
    agencies: [
        {
            agency: "",
            primaryCosponsorContact: {
                lastName: "",
            },
        },
    ],
    statusOfEngagement: "",
    scope: "",
};

export const initialErrors = {
    countryItems: [
        {
            country: "",
            region: "",
            roFocalPoint: {
                lastName: "",
            },
            coFocalPoint: {
                lastName: "",
            },
            implementationPartner: "",
            pricipalInvestigator: {
                lastName: "",
            },
            fundingSource: "",
        },
    ],
    agencies: [
        {
            agency: "",
            primaryCosponsorContact: {
                lastName: "",
            },
        },
    ],
};

export const initialTouched = {
    countryItems: [
        {
            country: false,
            region: false,
            roFocalPoint: {
                lastName: false,
            },
            coFocalPoint: {
                lastName: false,
            },
            implementationPartner: false,
            pricipalInvestigator: {
                lastName: false,
            },
            fundingSource: false,
        },
    ],
    agencies: [
        {
            agency: false,
            primaryCosponsorContact: {
                lastName: false,
            },
        },
    ],
};

export const defaultCountry = {
    country: "",
    region: "",
    roFocalPoint: {
        lastName: "",
    },
    coFocalPoint: {
        lastName: "",
    },
    implementationPartner: "",
    pricipalInvestigator: {
        lastName: "",
    },
    fundingSource: "",
};
