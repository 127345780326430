import { Container, styled } from "@mui/material";
import { prefixClasses } from "../common/Theme/utils";

const classes = {
    root: "",
};

prefixClasses("Login", classes);

export const StyledLogin = styled(Container)(({ theme }) => ({}));

export const loginClasses = classes;
