import { FormControl, FormHelperText, MenuItem, Select, InputLabel } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import React from "react";

const Dropdown = (props: {
    name: string;
    className?: string;
    value: string;
    label?: string;
    onChange: (name: string, value: any) => void;
    options: { id: string; name: string }[];
    sx: any;
    error?: boolean;
    helperText?: React.ReactNode;
}) => {
    return (
        <FormControl className={props.className} sx={props.sx} error={props.error}>
            <InputLabel id="filter-label">{props.label}</InputLabel>
            <Select
                displayEmpty
                variant="outlined"
                labelId="filter-label"
                label={props.label}
                value={props.value || ""}
                onChange={(event) => {
                    (props.onChange as any)(props.name, event.target.value);
                }}
                inputProps={{
                    value: props.value,
                }}
                startAdornment={<FolderOpenIcon />}>
                {props.options.map((template, index) => (
                    <MenuItem key={index} value={template.id}>
                        {template.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
};

export default Dropdown;
