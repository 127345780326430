import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { withMemo } from "../withMemo";

const CustomTextInput = (props: {
    className?: string;
    name?: string;
    placeholder?: string;
    value: string | number | string[];
    onChange?: StandardInputProps["onChange"];
    onClick?: (event: any) => void;
    startAdornmentIcon?: JSX.Element;
    startAdornmentClass?: string;
    endAdornmentIcon?: JSX.Element;
    endAdornmentClass?: string;
    error?: boolean;
    label?: string;
    helperText?: React.ReactNode;
    type?: string;
    multiline?: boolean;
    rows?: number;
    isReadOnly?: boolean;
    disabled?: boolean;
    onBlur?: StandardInputProps["onBlur"];
    isAutoCompleteEnabled?: boolean;
    sx?: any;
}) => {
    return (
        <TextField
            className={props.className}
            name={props.name}
            sx={props.sx}
            placeholder={props.placeholder}
            label={props.label}
            variant="outlined"
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
            color="primary"
            type={props.type || "text"}
            inputMode={"text"}
            error={props.error}
            helperText={props.helperText}
            data-automation={props.name}
            multiline={!!props.multiline}
            rows={props.rows}
            disabled={props.disabled}
            InputProps={{
                startAdornment: (
                    <>
                        {props.startAdornmentIcon ? (
                            <InputAdornment position="start" className={props.startAdornmentClass}>
                                {props.startAdornmentIcon}
                            </InputAdornment>
                        ) : (
                            <></>
                        )}
                    </>
                ),
                endAdornment: (
                    <>
                        {props.endAdornmentIcon && props.value ? (
                            <InputAdornment position="start" className={props.endAdornmentClass}>
                                {props.endAdornmentIcon}
                            </InputAdornment>
                        ) : (
                            <></>
                        )}
                    </>
                ),
                readOnly: props.isReadOnly,
            }}
            onBlur={props.onBlur}
            autoComplete={props.isAutoCompleteEnabled ? "off" : "on"}
        />
    );
};

export default withMemo(CustomTextInput, ["value", "error", "placeholder", "helperText", "className"]);
