import { ComponentType, useContext } from "react";
import { GlobalContext } from "../../App";
import { Login } from "../../Login/Login";

export function withUserBoundary<T extends ComponentType<any>>(Component: T) {
    return (props: any) => {
        const { user } = useContext(GlobalContext);
        if (!user) {
            return <Login />;
        }
        return <Component {...props} />;
    };
}
