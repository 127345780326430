import { createTheme } from "@mui/material/styles";

export const primary = "#1e95ec";
export const white = "#FFFFFF";
export const grey100 = "#A5AFB9";

// Override definitions for breakpoints, set to true declares it internally, set to false removes it
declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        mobileXS: true;
        mobileSM: true;
        mobileMD: true;
        mobileLG: true;
        mobileXL: true;
    }
}

export let theme = createTheme({});
theme = createTheme({
    shape: {
        borderRadius: 5,
    },
    breakpoints: {
        values: {
            xs: 0, // after value is phone
            sm: 600, // after value is tablet
            md: 1280, // after value is low resolution laptop
            lg: 1400, // after value is high resolution laptop
            xl: 1920,
            mobileXS: 320,
            mobileSM: 360,
            mobileMD: 480,
            mobileLG: 768,
            mobileXL: 900,
        },
    },
    components: {
        // Override 444px limitation of container on larger mobile phones (e.g. Samsung Galaxy Note 5)
        MuiContainer: {
            styleOverrides: {
                maxWidthXs: {
                    [theme.breakpoints.up("xs")]: {
                        maxWidth: "100%",
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1100,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "outlined",
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    height: "45px",
                    borderRadius: "8px",
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionStart: {
                    marginTop: "6px",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: primary,
                },
            },
        },
        MuiSnackbar: {
            defaultProps: {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                // Heading 1
                caption: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "40px",
                    lineHeight: "47px",
                },
                // Heading 2
                h1: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "32px",
                    lineHeight: "37px",
                },
                // Subtitle Bold
                subtitle1: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "22px",
                    lineHeight: "26px",
                },
                // Subtitle Regular
                subtitle2: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "22px",
                    lineHeight: "26px",
                },
                // Paragraph Big Regular
                body1: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "20px",
                    lineHeight: "23px",
                },
                // Paragraph Big Bold
                body2: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "20px",
                    lineHeight: "23px",
                },
                // Paragraph Bold
                h2: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "18px",
                    lineHeight: "21px",
                },
                // Paragraph Default Regular
                h3: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                    lineHeight: "19px",
                },
                // Paragraph Default Medium
                h4: {
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "19px",
                },
                // Paragraph Default Bold
                h5: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "19px",
                },
                // Paragraph Small Regular
                h6: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "17px",
                },
            },
        },
    },
    palette: {
        primary: {
            main: primary,
        },
    },
    typography: {
        htmlFontSize: 10,
    },
});
