import React, { useState, useEffect, useContext } from "react";
import { loginClasses as classes, StyledLogin } from "./StyledLogin";
import { signInWithEmailAndPassword } from "firebase/auth";
import { NavigationRoute } from "../common/NavigationRoute";
import { auth, GlobalContext } from "../App";
import { Button, Grid } from "@mui/material";
import CustomTextInput from "../common/CustomTextInput/CustomTextInput";
import { useNavigate } from "react-router-dom";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { user, setUser } = useContext(GlobalContext);
    const navigate = useNavigate();

    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then()
            .catch((error) => {
                console.error("login error", error);
            });
    };
    useEffect(() => {
        if (user) {
            navigate(NavigationRoute.Dashboard);
        }
    }, [user, navigate]);
    return (
        <StyledLogin className={classes.root} fixed>
            <Grid
                container
                flexDirection="column"
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "500px" }}>
                <CustomTextInput
                    name={"email"}
                    type={"text"}
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value);
                    }}
                    label={"Email"}
                    className={""}
                    sx={{ width: "300px", margin: 1 }}
                />
                <CustomTextInput
                    name={"password"}
                    type={"password"}
                    value={password}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                    label={"Password"}
                    className={""}
                    sx={{ width: "300px", margin: 1 }}
                />
                <Button variant={"contained"} onClick={login} sx={{ width: "200px", height: "40px" }}>
                    Login
                </Button>
            </Grid>
        </StyledLogin>
    );
};
