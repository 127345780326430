import { Grid, styled } from "@mui/material";
import { prefixClasses } from "../common/Theme/utils";

const classes = {
    root: "root",
    mainInfo: "mainInfo",
    titleWrapper: "titleWrapper",
    title: "title",
    buttons: "buttons",
    mainGrid: "mainGrid",
    mainGridLabel: "mainGridLabel",
    mainGridValue: "mainGridValue",
    mainDivider: "mainDivider",
    projectInfo: "projectInfo",
    description: "description",
    cosponsorDivider: "cosponsorDivider",
    categoryRow: "categoryRow",
    category: "category",
    categoryLabel: "categoryLabel",
    categoryValue: "categoryValue",
    agencyWrapper: "agencyWrapper",
    agency: "agency",
    agencyLabel: "agencyLabel",
    agencyValue: "agencyValue",
    countryWrapper: "countryWrapper",
    country: "country",
    countryLabel: "countryLabel",
    countryValue: "countryValue",
    countryDivider: "countryDivider",
};

prefixClasses("ProjectDetails", classes);

export const StyledProjectDetails = styled(Grid)(({ theme }) => ({
    [`& .${classes.root}`]: {
        paddingLeft: "105px",
        margin: "0 auto",
    },
    [`& .${classes.mainInfo}`]: {
        display: "flex",
        marginTop: "100px",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "30px",
    },
    [`& .${classes.titleWrapper}`]: {
        paddingLeft: "76px",
        textAlign: "left",
    },
    [`& .${classes.title}`]: {
        fontSize: "30px",
        fontWeight: 500,
        lineHeight: "2rem",
        color: "#021C36",
    },
    [`& .${classes.buttons}`]: {
        display: "flex",
        justifyContent: "flex-end",
    },
    [`& .${classes.mainGrid}`]: {
        display: "flex",
        color: "#021C36",
        alignItems: "center",
        textAlign: "left",
        paddingLeft: "76px",
        gridGap: "64px",
        marginTop: "15px",
        marginBottom: "25px",
    },
    [`& .${classes.mainGridLabel}`]: {
        fontSize: "16px",
    },
    [`& .${classes.mainGridValue}`]: {
        fontSize: "24px",
        fontWeight: 500,
    },
    [`& .${classes.mainDivider}`]: {
        borderBottomWidth: 2,
        marginLeft: "16%",
        marginRight: "18%",
    },
    [`& .${classes.projectInfo}`]: {
        paddingLeft: "105px",
        marginTop: "20px",
        marginBottom: "50px",
    },
    [`& .${classes.description}`]: {
        marginLeft: "77px",
        textAlign: "left",
        fontSize: "16px",
        marginTop: "12px",
        marginBottom: "24px",
    },
    [`& .${classes.cosponsorDivider}`]: {
        margin: "0px 15% 16px 1%",
    },
    [`& .${classes.categoryRow}`]: {
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        borderRadius: "5px",
        "&:hover": {
            background: "rgba(66,165,245,0.07)",
        },
    },
    [`& .${classes.category}`]: {
        marginLeft: "76px",
        textAlign: "left",
        color: "#5B6B7C",
    },
    [`& .${classes.categoryLabel}`]: {
        fontSize: "20px",
        fontWeight: 500,
    },
    [`& .${classes.categoryValue}`]: {
        textAlign: "left",
        fontSize: "16px",
        color: "#021C36",
    },
    [`& .${classes.agencyWrapper}`]: {
        marginBottom: "12px",
        padding: "0px",
    },
    [`& .${classes.agency}`]: {
        marginLeft: "77px",
        textAlign: "left",
        color: "#5B6B7C",
    },
    [`& .${classes.agencyLabel}`]: {
        fontSize: "24px",
        fontWeight: 600,
        marginBottom: "4px",
    },
    [`& .${classes.agencyValue}`]: {
        textAlign: "left",
        fontSize: "20px",
        marginBottom: "4px",
        color: "#021C36",
    },
    [`& .${classes.countryWrapper}`]: {
        marginBottom: "16px",
    },
    [`& .${classes.country}`]: {
        marginLeft: "12px",
        textAlign: "left",
        fontSize: "20px",
        color: "#5B6B7C",
    },
    [`& .${classes.countryLabel}`]: {
        fontSize: "24px",
        fontWeight: 600,
        marginBottom: "4px",
    },
    [`& .${classes.countryValue}`]: {
        textAlign: "left",
        fontSize: "20px",
        marginBottom: "4px",
        color: "#021C36",
    },
    [`& .${classes.countryDivider}`]: {
        marginTop: "8px",
        marginLeft: "1%",
        marginRight: "15%",
    },
}));

export const projectDetailsClasses = classes;
