import { SvgIcon } from "@mui/material";
import React from "react";

const DatePickerOpenIcon = (props: { viewBox?: string; className?: string; onClick?: any }) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M12.5 2C12.9062 2 13.25 2.15625 13.5625 2.4375C13.8438 2.75 14 3.09375 14 3.5V14.5C14 14.9375 13.8438 15.2812 13.5625 15.5625C13.25 15.875 12.9062 16 12.5 16H1.5C1.0625 16 0.71875 15.875 0.4375 15.5625C0.125 15.2812 0 14.9375 0 14.5V3.5C0 3.09375 0.125 2.75 0.4375 2.4375C0.71875 2.15625 1.0625 2 1.5 2H3V0.375C3 0.28125 3.03125 0.1875 3.09375 0.125C3.15625 0.0625 3.25 0 3.375 0H4.625C4.71875 0 4.8125 0.0625 4.875 0.125C4.9375 0.1875 5 0.28125 5 0.375V2H9V0.375C9 0.28125 9.03125 0.1875 9.09375 0.125C9.15625 0.0625 9.25 0 9.375 0H10.625C10.7188 0 10.8125 0.0625 10.875 0.125C10.9375 0.1875 11 0.28125 11 0.375V2H12.5ZM12.3125 14.5C12.3438 14.5 12.375 14.5 12.4375 14.4375C12.4688 14.4062 12.5 14.375 12.5 14.3125V5H1.5V14.3125C1.5 14.375 1.5 14.4062 1.5625 14.4375C1.59375 14.5 1.625 14.5 1.6875 14.5H12.3125Z"
                fill="#A5AFB9"
            />
        </SvgIcon>
    );
};

export default DatePickerOpenIcon;
