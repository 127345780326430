import { Grid, styled } from "@mui/material";
import { prefixClasses } from "../common/Theme/utils";
import { grey100 } from "../common/Theme/theme";

const classes = {
    root: "root",
    cancelIcon: "dashboard",
    dashboard: "dashboard",
    mainButtons: "mainButtons",
    projectButton: "projectButton",
    filtersButton: "filtersButton",
    filtersRow: "filtersRow",
    clearButton: "clearButton",
    pagination: "pagination",
    sortIcons: "sortIcons",
    icon: "icon",
    centerCell: "centerCell",
};

prefixClasses("Dashboard", classes);

export const StyledDashboard = styled(Grid)(({ theme }) => ({
    [`& .${classes.root}`]: {
        padding: "0px",
    },
    [`& .${classes.cancelIcon}`]: {
        cursor: "pointer",
        margin: "-6px -10px 0 0",
        color: grey100,
    },
    [`& .${classes.dashboard}`]: {
        marginTop: "80px",
    },
    [`& .${classes.mainButtons}`]: {
        margin: "20px 0px",
    },
    [`& .${classes.projectButton}`]: {
        display: "flex",
    },
    [`& .${classes.filtersButton}`]: {
        display: "flex",
        justifyContent: "flex-end",
    },
    [`& .${classes.filtersRow}`]: {
        marginTop: "20px",
    },
    [`& .${classes.clearButton}`]: {
        margin: "20px 0px",
    },
    [`& .${classes.pagination}`]: {
        margin: "15px 0px 30px 0px",
    },
    [`& .${classes.sortIcons}`]: {
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        transition: "smooth",
    },
    [`& .${classes.icon}`]: {
        width: "20px",
        height: "20px",
        margin: 0,
        padding: 0,
    },
    [`& .${classes.centerCell}`]: {
        display: "flex",
        alignItems: "center",
    },
}));

export const dashboardClasses = classes;
