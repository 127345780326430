import { Button, Grid, Typography } from "@mui/material";
import Dropdown from "../../common/Dropdown/Dropdown";
import CustomTextInput from "../../common/CustomTextInput/CustomTextInput";
import React, { useContext } from "react";
import { FormikState } from "formik";
import { ICountryItem } from "../../interfaces/project";
import { GlobalContext } from "../../App";
import { IProject } from "../schema";
import { FormikHandlers, FormikHelpers } from "formik/dist/types";

export const CountryComponent = ({
    form,
    index,
    countryItem,
}: {
    form: FormikState<IProject> & FormikHelpers<IProject> & FormikHandlers;
    index: number;
    countryItem: ICountryItem;
}) => {
    const { countries } = useContext(GlobalContext);
    const { values, setFieldValue, handleChange, touched, errors } = form;

    const removeCountry = (index: number) => {
        const newCountries = [...values.countryItems];
        newCountries.splice(index, 1);
        setFieldValue("countryItems", newCountries);
    };

    return (
        <Grid container key={index} sx={{ borderBottom: "1px solid gray" }}>
            <Grid container>
                <Grid item xs={4}>
                    <Dropdown
                        name={`countryItems[${index}].country`}
                        value={countryItem.country}
                        onChange={(_, value) => {
                            setFieldValue(`countryItems[${index}].country`, value);
                            const region = (countries.filter((country) => country.name === value)[0] as any).region;
                            setFieldValue(`countryItems[${index}].region`, region);
                        }}
                        label={`Country ${index + 1}`}
                        className={""}
                        sx={{ width: "90%", margin: 1 }}
                        options={countries}
                        error={
                            errors?.countryItems &&
                            errors?.countryItems[index] &&
                            (touched?.countryItems![index]?.country as any) &&
                            !!(errors?.countryItems![index] as any).country
                        }
                        helperText={
                            errors?.countryItems && errors?.countryItems[index]
                                ? ((errors?.countryItems as any)[index]?.country as any)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4} sx={{ width: "90%", margin: 1 }} wrap={"nowrap"}>
                    <Typography component={"span"}>Region:</Typography>
                    <Typography component={"span"}>{countryItem.region}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <CustomTextInput
                        name={`countryItems[${index}].roFocalPoint.lastName`}
                        value={countryItem.roFocalPoint.lastName}
                        onChange={handleChange}
                        label={"RO Focal Point Last Name"}
                        className={""}
                        sx={{ width: "90%", margin: 1 }}
                        error={
                            errors?.countryItems &&
                            errors?.countryItems[index] &&
                            (touched?.countryItems![index]?.roFocalPoint?.lastName as any) &&
                            !!(errors?.countryItems![index] as any).roFocalPoint?.lastName
                        }
                        helperText={
                            errors?.countryItems && errors?.countryItems[index]
                                ? ((errors?.countryItems as any)[index]?.roFocalPoint?.lastName as any)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <CustomTextInput
                        name={`countryItems[${index}].pricipalInvestigator.lastName`}
                        value={countryItem.pricipalInvestigator.lastName}
                        onChange={handleChange}
                        label={"Principal Investigator Last Name"}
                        className={""}
                        sx={{ width: "90%", margin: 1 }}
                        error={
                            errors?.countryItems &&
                            errors?.countryItems[index] &&
                            (touched?.countryItems![index]?.pricipalInvestigator?.lastName as any) &&
                            !!(errors?.countryItems![index] as any).pricipalInvestigator?.lastName
                        }
                        helperText={
                            errors?.countryItems && errors?.countryItems[index]
                                ? ((errors?.countryItems as any)[index]?.pricipalInvestigator?.lastName as any)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <CustomTextInput
                        name={`countryItems[${index}].coFocalPoint.lastName`}
                        value={countryItem.coFocalPoint.lastName}
                        onChange={handleChange}
                        label={"CO Focal Point Last Name"}
                        className={""}
                        sx={{ width: "90%", margin: 1 }}
                        error={
                            errors?.countryItems &&
                            errors?.countryItems[index] &&
                            (touched?.countryItems![index]?.coFocalPoint?.lastName as any) &&
                            !!(errors?.countryItems![index] as any).coFocalPoint?.lastName
                        }
                        helperText={
                            errors?.countryItems && errors?.countryItems[index]
                                ? ((errors?.countryItems as any)[index]?.coFocalPoint?.lastName as any)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <CustomTextInput
                        name={`countryItems[${index}].implementationPartner`}
                        value={countryItem.implementationPartner}
                        onChange={handleChange}
                        label={"Implementation Partner"}
                        className={""}
                        sx={{ width: "90%", margin: 1 }}
                        error={
                            errors?.countryItems &&
                            errors?.countryItems[index] &&
                            (touched?.countryItems![index]?.implementationPartner as any) &&
                            !!(errors?.countryItems![index] as any).implementationPartner
                        }
                        helperText={
                            errors?.countryItems && errors?.countryItems[index]
                                ? ((errors?.countryItems as any)[index]?.implementationPartner as any)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <CustomTextInput
                        name={`countryItems[${index}].fundingSource`}
                        value={countryItem.fundingSource}
                        onChange={handleChange}
                        label={"Funding Source"}
                        className={""}
                        sx={{ width: "90%", margin: 1 }}
                        error={
                            errors?.countryItems &&
                            errors?.countryItems[index] &&
                            (touched?.countryItems![index]?.fundingSource as any) &&
                            !!(errors?.countryItems![index] as any).fundingSource
                        }
                        helperText={
                            errors?.countryItems && errors?.countryItems[index]
                                ? ((errors?.countryItems as any)[index]?.fundingSource as any)
                                : ""
                        }
                    />
                </Grid>
            </Grid>
            {values.countryItems.length > 1 && (
                <Button sx={{ margin: 1 }} onClick={() => removeCountry(index)} variant={"outlined"} color={"error"}>
                    Remove Country
                </Button>
            )}
        </Grid>
    );
};
