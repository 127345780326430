import React, { ComponentType, MemoExoticComponent } from "react";
import _ from "lodash";

type TrackedProps =
    | "value"
    | "checked"
    | "error"
    | "helperText"
    | "startDate"
    | "dropdowns"
    | "copyToCustomer"
    | "pickup"
    | "delivery"
    | "touched"
    | "errors"
    | "setFieldValue"
    | "resetMilesAndRateId"
    | "endDate"
    | "paymentMethods"
    | "paymentTermsBeginTypes"
    | "paymentTermsTypes"
    | "paymentTypes"
    | "operableTypes"
    | "types"
    | "disabled"
    | "loading"
    | "options"
    | "dateRestrictions"
    | "vin"
    | "label"
    | "placeholder"
    | "vinNotFound"
    | "gatepass"
    | "hasInventoryUnitGatepass"
    | "className"
    | "gatepassAttachment"
    | "loadingVIN"
    | "expanded"
    | "shipperLoadId"
    | "hasEnclosedTrailer"
    | "hasM22Inspection"
    | "vehicles"
    | "toggleAccordionSelect"
    | "extRateCalculationId"
    | "distanceInMiles"
    | "payment"
    | "values"
    | "allErrors"
    | "loadState"
    | "hasInventoryUnits"
    | "hasGpsTracking"
    | "errorsPickup"
    | "loadOptions"
    | "touchedPickup"
    | "errorsDelivery"
    | "touchedDelivery"
    | "dispatchInstructions"
    | "customer"
    | "onChange"
    | "index";

// NOTE: This is optimization for formik, because its creates
// rerender for every field in the form on every change in a single field
// which results in slowness. Here we only update the form if we have some
// changes
export function withMemo<T extends ComponentType<any>>(
    WrappedComponent: T,
    trackedProps: TrackedProps[]
): MemoExoticComponent<T> {
    return React.memo<T>(WrappedComponent, (props, nextProps) => {
        return trackedProps.every((trackedProp) => {
            if (_.isObject(props[trackedProp]) && _.isObject(nextProps[trackedProp])) {
                return _.isEqual(props[trackedProp], nextProps[trackedProp]);
            }
            return props[trackedProp] === nextProps[trackedProp];
        });
    });
}
