/* eslint-disable no-useless-computed-key */
import { Popover, styled } from "@mui/material";
import { white, grey100, primary } from "../Theme/theme";

const dayStyles = {
    color: white,
    backgroundColor: primary,
    fontWeight: "bold",
};

export const StyledDatePickerPopover = styled(Popover)(({ theme }) => ({
    maxWidth: "unset",
    ["& .react-datepicker"]: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        maxWidth: "325px",
        minWidth: "310px",
        minHeight: "305px",
        display: "flex",
        justifyContent: "center",
    },
    ["& .react-datepicker__day"]: {
        width: "36px",
        height: "36px",
        fontSize: "14px",
        lineHeight: "36px",
        color: "rgba(0, 0, 0, 0.87)",
        borderRadius: "50%",
        margin: "3px",
    },
    ["& .react-datepicker__header"]: {
        backgroundColor: white,
        border: 0,
    },
    ["& .react-datepicker__current-month"]: {
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "15px",
        height: "23px",
    },
    ["& .react-datepicker__day-name"]: {
        color: "rgba(0, 0, 0, 0.38)",
        marginBottom: "12px",
        marginTop: "8px",
        fontSize: "14px",
        margin: "12px",
    },
    ["& .react-datepicker__month"]: {
        minHeight: "216px",
        margin: "0",
    },
    ["& .react-datepicker__day--outside-month"]: {
        color: grey100,
    },
    ["& .react-datepicker__day--selected"]: dayStyles,
    ["& .react-datepicker__day--selected:hover"]: dayStyles,
    ["& .react-datepicker__day--in-range"]: dayStyles,
    ["& .react-datepicker__day--range-start"]: dayStyles,
    ["& .react-datepicker__day--selecting-range-start"]: dayStyles,
    ["& .react-datepicker__day--selecting-range-end"]: dayStyles,
    ["& .react-datepicker__day--keyboard-selected"]: dayStyles,
    ["& .react-datepicker__day--range-end"]: dayStyles,
    ["& .react-datepicker__day--in-selecting-range"]: {
        color: white,
        backgroundColor: "rgba(60,17,127, 0.5)",
        fontWeight: "bold",
    },
    ["& .react-datepicker__day--disabled"]: {
        color: "rgba(0, 0, 0, 0.38)",
        pointerEvents: "none",
    },
    ["& .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover"]:
        dayStyles,
    ["& .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover"]:
        {
            borderRadius: "50%",
        },
    ["& .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover"]:
        dayStyles,
    ["& .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,\n" +
    ".react-datepicker__month-text--in-range,\n" +
    ".react-datepicker__quarter-text--in-range,\n" +
    ".react-datepicker__year-text--in-range),\n" +
    ".react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,\n" +
    ".react-datepicker__month-text--in-range,\n" +
    ".react-datepicker__quarter-text--in-range,\n" +
    ".react-datepicker__year-text--in-range),\n" +
    ".react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,\n" +
    ".react-datepicker__month-text--in-range,\n" +
    ".react-datepicker__quarter-text--in-range,\n" +
    ".react-datepicker__year-text--in-range),\n" +
    ".react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,\n" +
    ".react-datepicker__month-text--in-range,\n" +
    ".react-datepicker__quarter-text--in-range,\n" +
    ".react-datepicker__year-text--in-range)"]: {
        backgroundColor: "rgba(60,17,127, 0.5)",
    },
    ["& .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start"]: dayStyles,
}));
