import { where } from "firebase/firestore";
import { IAgency, ICountryItem } from "../interfaces/project";
import { formatFirebaseTimestamp, fullNameOrEmpty } from "../common/utils";
import { IProject } from "./schema";

export const getQueryConstraintsArray = (
    teamFilter: string,
    countriesFilter: string[],
    coFocalPointFilter: string[],
    roFocalPointFilter: string[],
    principalInvestigatorFilter: string[],
    fundingSourceFilter: string[],
    implementationPartnerFilter: string[],
    regionFilter: string[],
    srhFocalPointFilter: string[],
    codeFilter: string,
    endYearFilter: number[],
    hrpPdrhFilter: string,
    cosponsorFilter: boolean,
    isAgencyExport?: boolean
) => {
    const queryConstrains = [];
    if (countriesFilter && countriesFilter.length > 0) {
        queryConstrains.push(where("countriesKeys", "array-contains-any", countriesFilter));
    }
    if (coFocalPointFilter && coFocalPointFilter.length > 0) {
        queryConstrains.push(where("coFocalPointKeys", "array-contains-any", coFocalPointFilter));
    }
    if (roFocalPointFilter && roFocalPointFilter.length > 0) {
        queryConstrains.push(where("roFocalPointKeys", "array-contains-any", roFocalPointFilter));
    }
    if (principalInvestigatorFilter && principalInvestigatorFilter.length > 0) {
        queryConstrains.push(where("pricipalInvestigatorKeys", "array-contains-any", principalInvestigatorFilter));
    }
    if (implementationPartnerFilter && implementationPartnerFilter.length > 0) {
        queryConstrains.push(where("implementationPartnerKeys", "array-contains-any", implementationPartnerFilter));
    }
    if (fundingSourceFilter && fundingSourceFilter.length > 0) {
        queryConstrains.push(where("fundingSourceKeys", "array-contains-any", fundingSourceFilter));
    }
    if (regionFilter && regionFilter.length > 0) {
        queryConstrains.push(where("regionKeys", "array-contains-any", regionFilter));
    }
    if (srhFocalPointFilter && srhFocalPointFilter.length > 0) {
        queryConstrains.push(where("shrFocalPoint.lastName", "in", srhFocalPointFilter));
    }
    if (teamFilter) {
        queryConstrains.push(where("team", "==", teamFilter));
    }
    if (codeFilter) {
        queryConstrains.push(where("code", "==", codeFilter));
    }
    if (endYearFilter && endYearFilter.length > 0) {
        queryConstrains.push(where("endDateYear", "in", endYearFilter));
    }
    if (hrpPdrhFilter) {
        queryConstrains.push(where("hrpPdrh", "==", hrpPdrhFilter));
    }
    if (isAgencyExport) {
        queryConstrains.push(where("hasCosponsorEngagement", "==", true));
    } else if (cosponsorFilter !== undefined && (cosponsorFilter as any) !== "") {
        queryConstrains.push(where("hasCosponsorEngagement", "==", cosponsorFilter));
    }
    return queryConstrains;
};

export const downloadCsv = (rows: any[]): void => {
    if (rows.length > 0) {
        let csvContent = "data:text/csv;charset=utf-8," + rows.map((row) => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
};

export const getItem = (index: number, items: string[]) => {
    return items[index] || "Unknown";
};

export const itemsCountry = (country: ICountryItem) => {
    const items = [
        country.country,
        country.region,
        fullNameOrEmpty(country.roFocalPoint),
        fullNameOrEmpty(country.coFocalPoint),
        country.implementationPartner.replaceAll(",", ";").replaceAll("\n", " "),
        fullNameOrEmpty(country.pricipalInvestigator),
        country.fundingSource,
    ];
    return items;
};

export const itemsAgencyData = (agency: IAgency) => {
    const itemsAgency = [agency.agency, fullNameOrEmpty(agency.primaryCosponsorContact)];
    return itemsAgency;
};

export const getItemAgency = (index: number, itemsAgency: string[]) => {
    return itemsAgency[index] || "Unknown"; // ""
};

export const rowTitlesCountries = [
    "Country",
    "Region",
    "RO Focal Point",
    "CO Focal Point",
    "Implementation Partner",
    "Pricipal Investigator",
    "Funding Source",
];

export const rowTitlesAgency = ["Agency", "Primary Cosponsor Contact"];

export const getRowTitleAgency = (index: number) => {
    return rowTitlesAgency[index] || "Unknown"; // "Primary Cosponsor Contact"
};

export const getRowTitleCountries = (index: number) => {
    return rowTitlesCountries[index] || "Unknown"; // "Funding source"
};

export const getCountriesCSV = async (csvProjects: IProject[]) => {
    const projectsNumber = csvProjects.length;
    const maxCountryCounter = csvProjects.reduce((max, project) => Math.max(max, project.countryItems?.length || 0), 0);
    const columnNames = [
        "Project Number",
        "Id",
        "Title",
        "Team",
        "Description",
        "End date",
        "Hrp Pdrh",
        "ShrFocalPoint",
    ];
    for (let c = 0; c < maxCountryCounter; c++) {
        columnNames.push("country " + (c + 1));
    }
    const rows = [columnNames];
    for (let index = 0; index < projectsNumber; index++) {
        const project = csvProjects[index];
        const projectNumber = index + 1;
        const projectRow = [
            "" + projectNumber,
            project.code,
            project.title.replaceAll(",", ";"),
            project.team,
            project.description.replaceAll(",", ";").replaceAll("\n", " "),
            formatFirebaseTimestamp(project.endDate),
            project.hrpPdrh,
            fullNameOrEmpty(project.shrFocalPoint),
        ];
        rows.push(projectRow);
        const countryItems = project.countryItems;
        const countryRows: string[][] = [];
        for (let j = 0; j < 7; j++) {
            const currentRow = [getRowTitleCountries(j), ...new Array(7).fill("")];
            for (let x = 0; x < maxCountryCounter; x++) {
                if (countryItems[x]) {
                    currentRow.push(getItem(j, itemsCountry(countryItems[x])));
                } else {
                    currentRow.push("");
                }
            }
            countryRows.push(currentRow);
        }
        rows.push(...countryRows);
    }
    downloadCsv(rows);
};

export const getAgenciesCSV = async (csvProjects: IProject[]) => {
    const projectsNumber = csvProjects.length;
    const maxAgencyCounter = csvProjects.reduce((max, project) => Math.max(max, project.agencies?.length || 0), 0);
    const columnNames = [
        "Project Number",
        "Id",
        "Title",
        "Team",
        "Description",
        "End date",
        "Hrp Pdrh",
        "ShrFocalPoint",
        "Status of Engagement",
        "Scope",
    ];
    for (let c = 0; c < maxAgencyCounter; c++) {
        columnNames.push("agency " + (c + 1));
    }
    const rows = [columnNames];
    for (let index = 0; index < projectsNumber; index++) {
        const project = csvProjects[index];
        const projectNumber = index + 1;
        const { code, title, team, description, endDate, hrpPdrh, shrFocalPoint, statusOfEngagement, scope } = project;
        const projectRow = [
            "" + projectNumber,
            code,
            title.replaceAll(",", ";"),
            team,
            description.replaceAll(",", ";").replaceAll("\n", " "),
            formatFirebaseTimestamp(endDate),
            hrpPdrh,
            fullNameOrEmpty(shrFocalPoint),
            statusOfEngagement,
            scope,
        ];
        rows.push(projectRow);
        const agencies = project.agencies;
        const agencyRows: string[][] = [];
        for (let j = 0; j < 2; j++) {
            const currentRow = [getRowTitleAgency(j), ...new Array(9).fill("")];
            for (let x = 0; x < maxAgencyCounter; x++) {
                if (agencies[x]) {
                    currentRow.push(getItemAgency(j, itemsAgencyData(agencies[x])));
                } else {
                    currentRow.push("");
                }
            }
            agencyRows.push(currentRow);
        }
        rows.push(...agencyRows);
    }
    downloadCsv(rows);
};

export const getAgenciesRowCSV = async (csvProjects: IProject[]) => {
    const index: Record<string, IProject[]> = {};
    for (const project of csvProjects) {
        for (const agency of project.agencies) {
            const agencyName = agency.agency;
            if (index[agencyName]) {
                index[agencyName].push({ ...project });
            } else {
                index[agencyName] = [{ ...project }];
            }
        }
    }
    let rows = [
        [
            "Agency",
            "Id",
            "Title",
            "Description",
            "End date",
            "HRP/PRRH",
            "Team",
            "SRH Focal Point",
            "Primary Cosponsor Contact",
            "Status of Engagement",
            "Scope",
        ],
    ];
    const keys = Object.keys(index);
    keys.sort();
    keys.forEach((key) => {
        const projects = index[key];
        projects.forEach((project: IProject) => {
            const primaryCosponsorContact = project.agencies?.find(
                (agency) => agency.agency === key
            )?.primaryCosponsorContact;
            rows.push([
                key,
                project.code,
                project.title.replaceAll(",", ";"),
                project.description.replaceAll(",", ";").replaceAll("\n", " "),
                formatFirebaseTimestamp(project.endDate),
                project.hrpPdrh,
                project.team,
                fullNameOrEmpty(project.shrFocalPoint),
                fullNameOrEmpty(primaryCosponsorContact!),
                project.statusOfEngagement,
                project.scope,
            ]);
        });
    });
    downloadCsv(rows);
};

export const getCountriesRowCSV = async (csvProjects: IProject[], regionFilter: string[]) => {
    const index: Record<string, IProject[]> = {};
    for (const project of csvProjects) {
        for (const country of project.countryItems) {
            const countryName = country.country;
            const region = country.region;
            // Custom logic by the client
            if (regionFilter.length > 0) {
                if (regionFilter.includes(region)) {
                    if (index[countryName]) {
                        index[countryName].push({ ...project });
                    } else {
                        index[countryName] = [{ ...project }];
                    }
                }
            } else {
                if (index[countryName]) {
                    index[countryName].push({ ...project });
                } else {
                    index[countryName] = [{ ...project }];
                }
            }
        }
    }
    let rows = [
        ["Country", "Id", "Title", "Description", "End date", "HRP/PRRH", "Team", "SRH Focal Point", "Details"],
    ];
    const keys = Object.keys(index);
    keys.sort();
    keys.forEach((key) => {
        const projects = index[key];
        let prevCountry = "";
        projects.forEach((project: IProject) => {
            const country = project.countryItems?.find((country) => country.country === key);
            rows.push([
                key === prevCountry ? "" : key,
                project.code,
                project.title.replaceAll(",", ";"),
                project.description.replaceAll(",", ";").replaceAll("\n", " "),
                formatFirebaseTimestamp(project.endDate),
                project.hrpPdrh,
                project.team,
                fullNameOrEmpty(project.shrFocalPoint),
            ]);
            rows.push([...new Array(8).fill(" "), "RO Focal Point", fullNameOrEmpty(country?.roFocalPoint!) as any]);
            rows.push([...new Array(8).fill(" "), "CO Focal Point", fullNameOrEmpty(country?.coFocalPoint!) as any]);
            rows.push([...new Array(8).fill(" "), "Implementation Partner", country?.implementationPartner as any]);
            rows.push([
                ...new Array(8).fill(" "),
                "Pricipal Investigator",
                fullNameOrEmpty(country?.pricipalInvestigator!) as any,
            ]);
            rows.push([...new Array(8).fill(" "), "Funding Source", country?.fundingSource as any]);
            prevCountry = key;
        });
    });
    downloadCsv(rows);
};
