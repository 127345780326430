import React, { useContext, useEffect } from "react";
import { Button, AppBar, Toolbar, Box } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { navBarClasses as classes, StyledNavBar } from "./StyledNavBar";
import { useNavigate } from "react-router-dom";
import { NavigationRoute } from "./NavigationRoute";
import { signOut } from "firebase/auth";
import { auth, GlobalContext } from "../App";

export const NavBar = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(GlobalContext);
    const logout = () => {
        signOut(auth)
            .then()
            .catch((error) => console.error("logout error", error));
    };
    useEffect(() => {
        if (!user) {
            navigate(NavigationRoute.Login);
        }
    }, [user, navigate]);

    return (
        <StyledNavBar>
            <AppBar className={classes.navBar}>
                <Toolbar sx={{ display: "flex", marginRight: "50px", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PublicIcon />
                        <Button
                            sx={{ color: "white" }}
                            onClick={() => {
                                navigate(NavigationRoute.Dashboard);
                            }}>
                            Dashboard
                        </Button>
                    </Box>
                    <Button sx={{ color: "white" }} onClick={logout}>
                        Log out
                    </Button>
                </Toolbar>
            </AppBar>
        </StyledNavBar>
    );
};
