import React, { useEffect, useState } from "react";
import { StyledDatePickerPopover } from "./StyledDatePickerPopover";
import { FormikErrors } from "formik";
import { formatDate } from "../utils";
import { InputAdornment, InputLabel, TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerOpenIcon from "./DataPickerOpenIcon";
import { withMemo } from "../withMemo";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { Label } from "@mui/icons-material";

interface SingleDatePickerProps {
    startDate: Date;
    handleChange: (date: Date) => void;
    placeholder?: string;
    dateInputClass: string;
    helperText?: FormikErrors<Date>;
    error?: boolean;
    endAdornmentIcon?: JSX.Element;
    minDate?: Date;
    label?: string;
    sx: any;
}

type SingleDatePickerPropsType = SingleDatePickerProps & TextFieldProps;

function SingleDatePicker(props: SingleDatePickerPropsType) {
    const [anchorElement, setAnchorElement] = useState(null);

    const handleClick = (event: any) => {
        setAnchorElement(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const open = !!anchorElement;
    const id = open ? "date-range-picker" : undefined;

    const [value, setValue] = useState("");

    useEffect(() => {
        if (props.startDate) {
            setValue(formatDate(props.startDate));
        } else {
            setValue("");
        }
    }, [props.startDate]);

    const onChange = (date: any) => {
        props.handleChange(date);
        if (date) {
            handleClose();
        }
    };

    const variant = props.variant || "outlined";

    return (
        <>
            <InputLabel>{props.label}</InputLabel>
            <TextField
                {...props}
                className={props.dateInputClass}
                placeholder={props.placeholder}
                variant={variant}
                value={value}
                onClick={handleClick}
                color="primary"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <DatePickerOpenIcon onClick={handleClick} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <>
                            {props.endAdornmentIcon && value ? (
                                <InputAdornment position="start">{props.endAdornmentIcon}</InputAdornment>
                            ) : (
                                <></>
                            )}
                        </>
                    ),
                }}
                helperText={props.helperText}
                error={props.error}
                autoComplete="off"
            />

            <StyledDatePickerPopover
                id={id}
                open={open}
                anchorEl={anchorElement}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}>
                <DatePicker
                    selected={props.startDate}
                    onChange={onChange}
                    startDate={props.startDate}
                    inline
                    minDate={props.minDate}
                />
            </StyledDatePickerPopover>
        </>
    );
}

export default withMemo(SingleDatePicker, ["startDate", "error", "helperText"]);
