import React, { useEffect, useState } from "react";
import "./App.css";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Login } from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import { collection, getDocs, getFirestore, orderBy, query } from "firebase/firestore";
import { NavigationRoute } from "./common/NavigationRoute";
import { ProjectDetails } from "./ProjectDetails/ProjectDetails";
import { IProject } from "./Dashboard/schema";

const router = createBrowserRouter([
    {
        path: NavigationRoute.Default,
        element: <Login />,
    },
    {
        path: NavigationRoute.Dashboard,
        element: <Dashboard />,
    },
    {
        path: NavigationRoute.Login,
        element: <Login />,
    },
    {
        path: `${NavigationRoute.ProjectDetails}/:id`,
        element: <ProjectDetails />,
    },
]);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
const db = getFirestore(app);

interface IGlobalContext {
    user: any;
    setUser: any;
    countries: { id: string; name: string }[];
    agenciesDropdown: { id: string; name: string }[];
    scopes: { id: string; name: string }[];
    statusesOfEngagement: { id: string; name: string }[];
    hrpPdrhDropdown: { id: string; name: string }[];
    allSRHFocalPointOptions: { id: string; name: string }[];
    allRoFocalPointOptions: { id: string; name: string }[];
    allCoFocalPointOptions: { id: string; name: string }[];
    allPricipalInvestigatorOptions: { id: string; name: string }[];
    allImplementationPartnerOptions: { id: string; name: string }[];
    allFundingSourceOptions: { id: string; name: string }[];
    // allAgencyOptions: { id: string; name: string }[];
    // allPrimaryCosponsorContactOptions: { id: string; name: string }[];
    allRegionsOptions: { id: string; name: string }[];
    endYearOptions: { id: string; name: string }[];
}

export const GlobalContext = React.createContext<IGlobalContext>({
    user: null,
    setUser: null,
    countries: [],
    agenciesDropdown: [],
    scopes: [],
    statusesOfEngagement: [],
    hrpPdrhDropdown: [],
    allSRHFocalPointOptions: [],
    allRoFocalPointOptions: [],
    allCoFocalPointOptions: [],
    allPricipalInvestigatorOptions: [],
    allImplementationPartnerOptions: [],
    allFundingSourceOptions: [],
    // allAgencyOptions: [],
    // allPrimaryCosponsorContactOptions: [],
    allRegionsOptions: [],
    endYearOptions: [],
});

const allSRHFocalPointOptionsDefault = sessionStorage.getItem("allSRHFocalPointOptions")
    ? JSON.parse(sessionStorage.getItem("allSRHFocalPointOptions")!)
    : [];
const allRoFocalPointOptionsDefault = sessionStorage.getItem("allRoFocalPointOptions")
    ? JSON.parse(sessionStorage.getItem("allRoFocalPointOptions")!)
    : [];
const allCoFocalPointOptionsDefault = sessionStorage.getItem("allCoFocalPointOptions")
    ? JSON.parse(sessionStorage.getItem("allCoFocalPointOptions")!)
    : [];
const allPricipalInvestigatorOptionsDefault = sessionStorage.getItem("allPricipalInvestigatorOptions")
    ? JSON.parse(sessionStorage.getItem("allPricipalInvestigatorOptions")!)
    : [];
const allImplementationPartnerOptionsDefault = sessionStorage.getItem("allImplementationPartnerOptions")
    ? JSON.parse(sessionStorage.getItem("allImplementationPartnerOptions")!)
    : [];
const allFundingSourceOptionsDefault = sessionStorage.getItem("allFundingSourceOptions")
    ? JSON.parse(sessionStorage.getItem("allFundingSourceOptions")!)
    : [];
// const allAgencyOptionsDefault = sessionStorage.getItem("allAgencyOptions")
//     ? JSON.parse(sessionStorage.getItem("allAgencyOptions")!)
//     : [];
// const allPrimaryCosponsorContactOptionsDefault = sessionStorage.getItem("allPrimaryCosponsorContactOptions")
//     ? JSON.parse(sessionStorage.getItem("allPrimaryCosponsorContactOptions")!)
//     : [];
const allRegionsOptionsDefault = sessionStorage.getItem("allRegionsOptions")
    ? JSON.parse(sessionStorage.getItem("allRegionsOptions")!)
    : [];

function App() {
    const [user, setUser] = useState<any>(null);
    const [countries, setCountries] = useState([]);
    const [agenciesDropdown, setAgenciesDropdown] = useState([]);
    const [scopes, setScopes] = useState([]);
    const [statusesOfEngagement, setStatusesOfEngagement] = useState([]);
    const [hrpPdrhDropdown, setHrpPdrhDropdown] = useState([]);
    const [allRoFocalPointOptions, setAllRoFocalPointOptions] = useState(allRoFocalPointOptionsDefault);
    const [allCoFocalPointOptions, setAllCoFocalPointOptions] = useState(allCoFocalPointOptionsDefault);
    const [allPricipalInvestigatorOptions, setAllPricipalInvestigatorOptions] = useState(
        allPricipalInvestigatorOptionsDefault
    );
    const [allImplementationPartnerOptions, setAllImplementationPartnerOptions] = useState(
        allImplementationPartnerOptionsDefault
    );
    const [allFundingSourceOptions, setAllFundingSourceOptions] = useState(allFundingSourceOptionsDefault);
    // const [allAgencyOptions, setAllAgencyOptions] = useState(allAgencyOptionsDefault);
    // const [allPrimaryCosponsorContactOptions, setAllPrimaryCosponsorContactOptions] = useState(
    //     allPrimaryCosponsorContactOptionsDefault
    // );
    const [allRegionsOptions, setAllRegionsOptions] = useState(allRegionsOptionsDefault);
    const [allSRHFocalPointOptions, setAllSRHFocalPointOptions] = useState(allSRHFocalPointOptionsDefault);
    const [endYearOptions, setEndYearOptions] = useState<{ id: string; name: string }[]>([]);

    const fetchDropdowns = async () => {
        const countriesQuerySnapshot = await getDocs(query(collection(db, "countries"), orderBy("name", "asc")));
        let allRegionsKeys: string[] = [];
        const newCountries = countriesQuerySnapshot.docs.map((doc) => {
            const name = doc.data().name;
            const region = doc.data().region;
            allRegionsKeys.push(region);
            return {
                id: name,
                name,
                region,
            };
        });
        // @ts-ignore
        allRegionsKeys = [...new Set(allRegionsKeys)];
        const innerAllRegionsOptions = allRegionsKeys.sort().map((region) => {
            return {
                id: region,
                name: region,
            };
        });
        setAllRegionsOptions(innerAllRegionsOptions);
        sessionStorage.setItem("allRegionsOptions", JSON.stringify(innerAllRegionsOptions));
        const agencyQuerySnapshot = await getDocs(collection(db, "agencies"));
        const newAgencies = agencyQuerySnapshot.docs.map((doc) => {
            const name = doc.data().name;
            return {
                id: name,
                name,
            };
        });
        const scopesQuerySnapshot = await getDocs(collection(db, "scopes"));
        const newScopes = scopesQuerySnapshot.docs.map((doc) => {
            const name = doc.data().name;
            return {
                id: name,
                name,
            };
        });
        const statusesOfEngagementQuerySnapshot = await getDocs(collection(db, "statuses_of_engagement"));
        const statusOfEngagements = statusesOfEngagementQuerySnapshot.docs.map((doc) => {
            const name = doc.data().name;
            return {
                id: name,
                name,
            };
        });
        const hrpPdrhQuerySnapshot = await getDocs(collection(db, "hrp_pdrh"));
        const hrpPdrh = hrpPdrhQuerySnapshot.docs.map((doc) => {
            const name = doc.data().name;
            return {
                id: name,
                name,
            };
        });
        let allSRHFocalPointKeys: string[] = [];
        let allRoFocalPointKeys: string[] = [];
        let allCoFocalPointKeys: string[] = [];
        let allPricipalInvestigatorKeys: string[] = [];
        let allImplementationPartnerKeys: string[] = [];
        let allFundingSourceKeys: string[] = [];
        let allAgencyKeys: string[] = [];
        let allPrimaryCosponsorContactKeys: string[] = [];
        let uniqueEndYears: number[] = [];
        const projectsQuerySnapshot = await getDocs(collection(db, "projects"));
        projectsQuerySnapshot.docs.forEach((doc) => {
            const data = doc.data() as IProject;
            if (data.shrFocalPoint?.lastName) {
                allSRHFocalPointKeys.push(data.shrFocalPoint?.lastName);
            }
            allRoFocalPointKeys = allRoFocalPointKeys.concat(data.roFocalPointKeys || []);
            allCoFocalPointKeys = allCoFocalPointKeys.concat(data.coFocalPointKeys || []);
            allPricipalInvestigatorKeys = allPricipalInvestigatorKeys.concat(data.pricipalInvestigatorKeys || []);
            allImplementationPartnerKeys = allImplementationPartnerKeys.concat(data.implementationPartnerKeys || []);
            allFundingSourceKeys = allFundingSourceKeys.concat(data.fundingSourceKeys || []);
            allAgencyKeys = allAgencyKeys.concat(data.agencyKeys || []);
            allPrimaryCosponsorContactKeys = allPrimaryCosponsorContactKeys.concat(
                data.primaryCosponsorContactKeys || []
            );
            if (uniqueEndYears.indexOf(data.endDateYear) === -1) {
                uniqueEndYears.push(data.endDateYear);
            }
        });
        uniqueEndYears.sort();
        const endYearOptionsCycle: { id: string; name: string }[] = [];
        for (const year of uniqueEndYears) {
            endYearOptionsCycle.push({ id: year.toString(), name: year.toString() });
        }

        setEndYearOptions(endYearOptionsCycle);
        setCountries(newCountries as any);
        setAgenciesDropdown(newAgencies as any);
        setScopes(newScopes as any);
        setStatusesOfEngagement(statusOfEngagements as any);
        setHrpPdrhDropdown(hrpPdrh as any);

        // @ts-ignore
        allRoFocalPointKeys = [...new Set(allRoFocalPointKeys)];
        // @ts-ignore
        allCoFocalPointKeys = [...new Set(allCoFocalPointKeys)];
        // @ts-ignore
        allPricipalInvestigatorKeys = [...new Set(allPricipalInvestigatorKeys)];
        // @ts-ignore
        allImplementationPartnerKeys = [...new Set(allImplementationPartnerKeys)];
        // @ts-ignore
        allFundingSourceKeys = [...new Set(allFundingSourceKeys)];
        // @ts-ignore
        allSRHFocalPointKeys = [...new Set(allSRHFocalPointKeys)];
        // // @ts-ignore
        // allAgencyKeys = [...new Set(allAgencyKeys)];
        // // @ts-ignore
        // allPrimaryCosponsorContactKeys = [...new Set(allPrimaryCosponsorContactKeys)];

        const innerAllSRHFocalPointOptions = allSRHFocalPointKeys.sort().map((key) => ({ id: key, name: key }));
        setAllSRHFocalPointOptions(innerAllSRHFocalPointOptions);
        const innerAllRoFocalPointOptions = allRoFocalPointKeys.sort().map((key) => ({ id: key, name: key }));
        setAllRoFocalPointOptions(innerAllRoFocalPointOptions);
        const innerAllCoFocalPointOptions = allCoFocalPointKeys.sort().map((key) => ({ id: key, name: key }));
        setAllCoFocalPointOptions(innerAllCoFocalPointOptions);
        const innerAllPricipalInvestigatorOptions = allPricipalInvestigatorKeys
            .sort()
            .map((key) => ({ id: key, name: key }));
        setAllPricipalInvestigatorOptions(innerAllPricipalInvestigatorOptions);
        const innerAllImplementationPartnerOptions = allImplementationPartnerKeys.sort().map((key) => ({
            id: key,
            name: key,
        }));
        setAllImplementationPartnerOptions(innerAllImplementationPartnerOptions);
        const innerAllFundingSourceOptions = allFundingSourceKeys.sort().map((key) => ({ id: key, name: key }));
        setAllFundingSourceOptions(innerAllFundingSourceOptions);
        // const innerAllAgencyOptions = allAgencyKeys.map((key) => ({ id: key, name: key }));
        // setAllAgencyOptions(innerAllAgencyOptions);
        // const innerAllPrimaryCosponsorContactOptions = allPrimaryCosponsorContactKeys.map((key) => ({
        //     id: key,
        //     name: key,
        // }));
        // setAllPrimaryCosponsorContactOptions(innerAllPrimaryCosponsorContactOptions);
        sessionStorage.setItem("allSRHFocalPointOptions", JSON.stringify(innerAllSRHFocalPointOptions));
        sessionStorage.setItem("allRoFocalPointOptions", JSON.stringify(innerAllRoFocalPointOptions));
        sessionStorage.setItem("allCoFocalPointOptions", JSON.stringify(innerAllCoFocalPointOptions));
        sessionStorage.setItem("allPricipalInvestigatorOptions", JSON.stringify(innerAllPricipalInvestigatorOptions));
        sessionStorage.setItem("allImplementationPartnerOptions", JSON.stringify(innerAllImplementationPartnerOptions));
        sessionStorage.setItem("allFundingSourceOptions", JSON.stringify(innerAllFundingSourceOptions));
        // sessionStorage.setItem("allAgencyKeys", JSON.stringify(innerAllAgencyOptions));
        // sessionStorage.setItem(
        //     "allPrimaryCosponsorContactKeys",
        //     JSON.stringify(innerAllPrimaryCosponsorContactOptions)
        // );
    };

    useEffect(() => {
        const listen = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                fetchDropdowns();
            } else {
                setUser(null);
            }
        });
        return () => {
            listen();
        };
    }, []);

    return (
        <div className="App">
            <GlobalContext.Provider
                value={{
                    user,
                    setUser,
                    countries,
                    agenciesDropdown,
                    scopes,
                    statusesOfEngagement,
                    hrpPdrhDropdown,
                    allRoFocalPointOptions,
                    allCoFocalPointOptions,
                    allPricipalInvestigatorOptions,
                    allImplementationPartnerOptions,
                    allFundingSourceOptions,
                    // allAgencyOptions,
                    // allPrimaryCosponsorContactOptions,
                    allRegionsOptions,
                    allSRHFocalPointOptions,
                    endYearOptions,
                }}>
                <RouterProvider router={router} />
            </GlobalContext.Provider>
        </div>
    );
}

export default App;
