import React, { useCallback, useContext, useEffect, useState } from "react";
import { dashboardClasses as classes, StyledDashboard } from "./StyledDashboard";
import { Box, Button, Container, Grid, Pagination } from "@mui/material";
import { CreateProject } from "./CreateProject";
import { NavBar } from "../common/NavBar";
import { collection, DocumentData, getDocs, getFirestore, query, QuerySnapshot } from "firebase/firestore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IProject } from "./schema";
import { withUserBoundary } from "../common/UserBoundary/UserBoundary";
import { Link } from "react-router-dom";
import { NavigationRoute } from "../common/NavigationRoute";
import {
    cosponsorEngagementStatusOptions,
    formatFirebaseTimestamp,
    fullNameOrEmpty,
    teamOptions,
    timestampToDate,
} from "../common/utils";
import { MultipleSelectCheckmarks } from "../ProjectDetails/MultipleSelect/MultipleSelect";
import { GlobalContext } from "../App";
import CustomTextInput from "../common/CustomTextInput/CustomTextInput";
import Dropdown from "../common/Dropdown/Dropdown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SortIcon from "@mui/icons-material/Sort";
import {
    getAgenciesCSV,
    getAgenciesRowCSV,
    getCountriesCSV,
    getCountriesRowCSV,
    getQueryConstraintsArray,
} from "./utils";
import { ICountryItem } from "../interfaces/project";

enum Direction {
    Desc = "desc",
    Asc = "asc",
    None = "",
}
enum Column {
    Code = "code",
    Team = "team",
    Title = "title",
    Countries = "countryItems",
    ShrFocalPoint = "shrFocalPoint",
    EndDate = "endDate",
    HasCosponsorEngagement = "hasCosponsorEngagement",
}

const pageSize = 10;
const defaultIcon = <SortIcon className={classes.icon} />;
const getSortedProjects = (projects: IProject[], orderDirection: Direction, orderColumn: Column) => {
    let sign: number = 0;
    if (orderDirection === Direction.Asc) {
        sign = 1;
    } else if (orderDirection === Direction.Desc) {
        sign = -1;
    } else {
        sign = 0;
    }
    let sortedProjects: IProject[];
    if (orderColumn === Column.EndDate) {
        sortedProjects = [...projects].sort(
            (a: any, b: any) =>
                sign * ((timestampToDate(a[Column.EndDate]) as any) - (timestampToDate(b[Column.EndDate]) as any))
        );
    } else if (orderColumn === Column.ShrFocalPoint) {
        sortedProjects = [...projects].sort((a: any, b: any) => {
            if (!a[Column.Countries] || !b[Column.Countries]) {
                return 0;
            }
            return (
                sign * fullNameOrEmpty(a[Column.ShrFocalPoint]).localeCompare(fullNameOrEmpty(b[Column.ShrFocalPoint]))
            );
        });
    } else if (orderColumn === Column.Countries) {
        sortedProjects = [...projects].sort((a: any, b: any) => {
            if (!a[Column.Countries] || !b[Column.Countries]) {
                return 0;
            }
            return (
                sign *
                a[Column.Countries]
                    .map((countryItem: ICountryItem) => countryItem.country)
                    .join(", ")
                    .localeCompare(
                        b[Column.Countries].map((countryItem: ICountryItem) => countryItem.country).join(", ")
                    )
            );
        });
    } else if (orderColumn === Column.HasCosponsorEngagement) {
        sortedProjects = [...projects].sort(
            (a: any, b: any) =>
                sign *
                (a[Column.HasCosponsorEngagement] ? "Yes" : "No").localeCompare(
                    b[Column.HasCosponsorEngagement] ? "Yes" : "No"
                )
        );
    } else {
        sortedProjects = [...projects].sort((a: any, b: any) => {
            if (!a[orderColumn] || !b[orderColumn]) {
                return 0;
            }
            return sign * a[orderColumn].localeCompare(b[orderColumn]);
        });
    }
    if (sign === 0) {
        sortedProjects = [...projects].sort((a: any, b: any) => b[Column.Team].localeCompare(a[Column.Team]));
    }
    return sortedProjects;
};

const Dashboard = () => {
    const [projects, setProjects] = useState<IProject[]>([]);
    const [countriesFilter, setCountriesFilter] = useState<string[]>(
        sessionStorage.getItem("countriesFilter") ? JSON.parse(sessionStorage.getItem("countriesFilter")!) : []
    );
    const [orderDirection, setOrderDirection] = useState<Direction>(
        (sessionStorage.getItem("orderDirection") || "") as any
    );
    const [orderColumn, setOrderColumn] = useState<Column>((sessionStorage.getItem("orderColumn") || "") as any);
    const [teamFilter, setTeamFilter] = useState<string>(sessionStorage.getItem("teamFilter") || "");
    const [roFocalPointFilter, setRoFocalPointFilter] = useState<string[]>(
        sessionStorage.getItem("roFocalPointFilter") ? JSON.parse(sessionStorage.getItem("roFocalPointFilter")!) : []
    );
    const [coFocalPointFilter, setCoFocalPointFilter] = useState<string[]>(
        sessionStorage.getItem("coFocalPointFilter") ? JSON.parse(sessionStorage.getItem("coFocalPointFilter")!) : []
    );
    const [principalInvestigatorFilter, setPrincipalInvestigatorFilter] = useState<string[]>(
        sessionStorage.getItem("principalInvestigatorFilter")
            ? JSON.parse(sessionStorage.getItem("principalInvestigatorFilter")!)
            : []
    );
    const [implementationPartnerFilter, setImplementationPartnerFilter] = useState<string[]>(
        sessionStorage.getItem("implementationPartnerFilter")
            ? JSON.parse(sessionStorage.getItem("implementationPartnerFilter")!)
            : []
    );
    const [fundingSourceFilter, setFundingSourceFilter] = useState<string[]>(
        sessionStorage.getItem("fundingSourceFilter") ? JSON.parse(sessionStorage.getItem("fundingSourceFilter")!) : []
    );
    const [regionFilter, setRegionFilter] = useState<string[]>(
        sessionStorage.getItem("regionFilter") ? JSON.parse(sessionStorage.getItem("regionFilter")!) : []
    );
    const [hrpPdrhFilter, setHrpPdrhFilter] = useState<string>(sessionStorage.getItem("hrpPdrhFilter") || "");
    const [cosponsorFilter, setCosponsorFilter] = useState<boolean>(
        sessionStorage.getItem("cosponsorFilter") ? sessionStorage.getItem("cosponsorFilter") === "true" : ("" as any)
    );
    const [codeFilter, setCodeFilter] = useState<string>(sessionStorage.getItem("codeFilter") || "");
    const [endYearFilter, setEndYearFilter] = useState<number[]>(
        sessionStorage.getItem("endYearFilter") ? JSON.parse(sessionStorage.getItem("endYearFilter")!) : []
    );
    const [srhFocalPointFilter, setSrhFocalPointFilter] = useState<string[]>(
        sessionStorage.getItem("srhFocalPointFilter") ? JSON.parse(sessionStorage.getItem("srhFocalPointFilter")!) : []
    );
    const [toggleFilters, setToggleFilters] = useState<boolean>(
        sessionStorage.getItem("toggleFilters") ? Boolean(sessionStorage.getItem("toggleFilters")) : true
    );
    const [page, setPage] = React.useState<number>(0);
    const [pageContent, setPageContent] = React.useState<IProject[]>([]);
    const {
        countries,
        hrpPdrhDropdown,
        allCoFocalPointOptions,
        allRoFocalPointOptions,
        allFundingSourceOptions,
        allImplementationPartnerOptions,
        allPricipalInvestigatorOptions,
        allRegionsOptions,
        allSRHFocalPointOptions,
        endYearOptions,
    } = useContext(GlobalContext);

    function handleOrderDirection(column: Column): void {
        if (orderColumn === column) {
            if (orderDirection === Direction.Asc) {
                setOrderDirection(Direction.Desc);
            } else if (orderDirection === Direction.Desc) {
                setOrderDirection(Direction.None);
            } else {
                setOrderDirection(Direction.Asc);
            }
        } else {
            setOrderColumn(column);
            setOrderDirection(Direction.Asc);
        }
    }
    function getOrderIcon(): any {
        if (orderDirection === Direction.Asc) {
            return <ArrowUpwardIcon className={classes.icon} />;
        } else if (orderDirection === Direction.Desc) {
            return <ArrowDownwardIcon className={classes.icon} />;
        }
        return <SortIcon className={classes.icon} />;
    }
    useEffect(() => {
        fetchFirstPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchBase = useCallback((querySnapshot: QuerySnapshot<DocumentData>) => {
        const newProjects = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });
        const fetchedProjects = getSortedProjects(newProjects as any, orderDirection, orderColumn);
        setProjects(fetchedProjects);
        setPageContent(fetchedProjects.slice(0, 10));
        setPage(1);
    }, []); // eslint-disable-line

    const getQueryConstraints = useCallback(
        (isAgencyExport?: boolean) => {
            return getQueryConstraintsArray(
                teamFilter,
                countriesFilter,
                coFocalPointFilter,
                roFocalPointFilter,
                principalInvestigatorFilter,
                fundingSourceFilter,
                implementationPartnerFilter,
                regionFilter,
                srhFocalPointFilter,
                codeFilter,
                endYearFilter,
                hrpPdrhFilter,
                cosponsorFilter,
                isAgencyExport
            );
        },
        [
            teamFilter,
            codeFilter,
            endYearFilter,
            hrpPdrhFilter,
            cosponsorFilter,
            countriesFilter,
            coFocalPointFilter,
            roFocalPointFilter,
            principalInvestigatorFilter,
            implementationPartnerFilter,
            fundingSourceFilter,
            regionFilter,
            srhFocalPointFilter,
        ]
    );

    const fetchFirstPage = useCallback(async () => {
        const innerQuery = query(
            collection(getFirestore(), "projects"),
            // where("countryItems", "in", [{ country: "China" }, { country: "Global" }]),
            ...getQueryConstraints()
        );
        const querySnapshot = await getDocs(innerQuery);
        fetchBase(querySnapshot);
    }, [fetchBase, getQueryConstraints]);

    useEffect(() => {
        sessionStorage.setItem("teamFilter", teamFilter);
        sessionStorage.setItem("orderColumn", orderColumn);
        sessionStorage.setItem("orderDirection", orderDirection);
        sessionStorage.setItem("codeFilter", codeFilter);
        sessionStorage.setItem("endYearFilter", JSON.stringify(endYearFilter));
        sessionStorage.setItem("hrpPdrhFilter", hrpPdrhFilter);
        sessionStorage.setItem("cosponsorFilter", cosponsorFilter as any);
        sessionStorage.setItem("countriesFilter", JSON.stringify(countriesFilter));
        sessionStorage.setItem("coFocalPointFilter", JSON.stringify(coFocalPointFilter));
        sessionStorage.setItem("roFocalPointFilter", JSON.stringify(roFocalPointFilter));
        sessionStorage.setItem("principalInvestigatorFilter", JSON.stringify(principalInvestigatorFilter));
        sessionStorage.setItem("implementationPartnerFilter", JSON.stringify(implementationPartnerFilter));
        sessionStorage.setItem("fundingSourceFilter", JSON.stringify(fundingSourceFilter));
        sessionStorage.setItem("regionFilter", JSON.stringify(regionFilter));
        sessionStorage.setItem("srhFocalPointFilter", JSON.stringify(srhFocalPointFilter));
        fetchFirstPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        teamFilter,
        codeFilter,
        endYearFilter,
        countriesFilter,
        hrpPdrhFilter,
        cosponsorFilter,
        coFocalPointFilter,
        roFocalPointFilter,
        principalInvestigatorFilter,
        implementationPartnerFilter,
        fundingSourceFilter,
        regionFilter,
        srhFocalPointFilter,
    ]);

    const allProjects = async (isAgencyExport: boolean) => {
        const innerQuery = query(collection(getFirestore(), "projects"), ...getQueryConstraints(isAgencyExport));
        const querySnapshot = await getDocs(innerQuery);
        const csvProjects = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        }) as IProject[];
        return csvProjects;
    };

    const getInnerAgenciesCSV = async () => {
        const csvProjects = await allProjects(true);
        getAgenciesCSV(csvProjects);
    };

    const getInnerCountriesCSV = async () => {
        const csvProjects = await allProjects(false);
        getCountriesCSV(csvProjects);
    };

    const getInnerAgenciesRowCSV = async () => {
        const csvProjects = await allProjects(true);
        getAgenciesRowCSV(csvProjects);
    };

    const getInnerCountriesRowCSV = async () => {
        const csvProjects = await allProjects(false);
        getCountriesRowCSV(csvProjects, regionFilter);
    };

    useEffect(() => {
        const newSortedProjects: IProject[] = getSortedProjects(projects, orderDirection, orderColumn);
        setProjects(newSortedProjects);
        setPageContent(newSortedProjects.slice((page - 1) * 10, page * 10));
    }, [orderDirection, orderColumn]); // eslint-disable-line

    return (
        <StyledDashboard className={classes.root}>
            <Container fixed>
                <NavBar />
                <Grid className={classes.dashboard} container>
                    <Grid container justifyContent={"space-between"} className={classes.mainButtons}>
                        <Grid md={3} lg={3} item className={classes.projectButton}>
                            <CreateProject
                                onClose={() => {
                                    fetchFirstPage();
                                }}
                            />
                        </Grid>
                        <Grid md={3} lg={3} item className={classes.filtersButton}>
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    setToggleFilters(!toggleFilters);
                                    sessionStorage.setItem("toggleFilters", JSON.stringify(!toggleFilters));
                                }}>
                                {toggleFilters ? "Hide Filters" : "Filters"}
                            </Button>
                        </Grid>
                    </Grid>
                    {toggleFilters && (
                        <>
                            <Grid container spacing={1}>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        value={countriesFilter.map((country) => ({ id: country, name: country }))}
                                        options={countries}
                                        onChange={(selected) => {
                                            setCountriesFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"Countries"}
                                        placeholder={"Countries"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        value={regionFilter.map((item) => ({ id: item, name: item }))}
                                        options={allRegionsOptions}
                                        onChange={(selected) => {
                                            setRegionFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"Regions"}
                                        placeholder={"Regions"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <Dropdown
                                        name={"team"}
                                        value={teamFilter}
                                        onChange={(_name, value) => {
                                            setTeamFilter(value);
                                        }}
                                        label={"Team"}
                                        options={[{ id: "", name: "No Team" }, ...teamOptions]}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        options={allSRHFocalPointOptions}
                                        value={srhFocalPointFilter.map((item) => ({ id: item, name: item }))}
                                        onChange={(selected) => {
                                            setSrhFocalPointFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"SRH Focal Point"}
                                        placeholder={"SRH Focal Point"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={classes.filtersRow}>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        options={allRoFocalPointOptions}
                                        value={roFocalPointFilter.map((item) => ({ id: item, name: item }))}
                                        onChange={(selected) => {
                                            setRoFocalPointFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"RO Focal Point"}
                                        placeholder={"RO Focal Point"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        options={allCoFocalPointOptions}
                                        value={coFocalPointFilter.map((item) => ({ id: item, name: item }))}
                                        onChange={(selected) => {
                                            setCoFocalPointFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"CO Focal Point"}
                                        placeholder={"CO Focal Point"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        options={allFundingSourceOptions}
                                        value={fundingSourceFilter.map((item) => ({ id: item, name: item }))}
                                        onChange={(selected) => {
                                            setFundingSourceFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"Funding Source"}
                                        placeholder={"Funding Source"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <Dropdown
                                        name={"hrpPdrh"}
                                        value={hrpPdrhFilter}
                                        onChange={(_name, value) => {
                                            setHrpPdrhFilter(value);
                                        }}
                                        label={"HRP/PDRH"}
                                        options={[{ id: "", name: "Not Selected" }, ...hrpPdrhDropdown]}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={classes.filtersRow}>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        options={allImplementationPartnerOptions}
                                        value={implementationPartnerFilter.map((item) => ({ id: item, name: item }))}
                                        onChange={(selected) => {
                                            setImplementationPartnerFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"Implementation Partner"}
                                        placeholder={"Implementation Partner"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        options={allPricipalInvestigatorOptions}
                                        value={principalInvestigatorFilter.map((item) => ({ id: item, name: item }))}
                                        onChange={(selected) => {
                                            setPrincipalInvestigatorFilter(
                                                selected.map(
                                                    (selectedItem: { id: string; name: string }) => selectedItem.id
                                                )
                                            );
                                        }}
                                        label={"Principal Investigator"}
                                        placeholder={"Principal Investigator"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <Dropdown
                                        name={"cosponsor"}
                                        value={cosponsorFilter as any}
                                        onChange={(_name, value) => {
                                            setCosponsorFilter(value);
                                        }}
                                        label={"Cosponsor Engagement"}
                                        options={[
                                            { id: "", name: "Not Selected" },
                                            ...(cosponsorEngagementStatusOptions as any),
                                        ]}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                                <Grid md={3} lg={3} item>
                                    <CustomTextInput
                                        label={"ID"}
                                        value={codeFilter}
                                        sx={{ marginTop: "10px", width: "100%" }}
                                        onChange={(event) => setCodeFilter(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} className={classes.filtersRow}>
                                <Grid md={3} lg={3} item>
                                    <MultipleSelectCheckmarks
                                        value={endYearFilter.map((year) => ({
                                            id: year.toString(),
                                            name: year.toString(),
                                        }))}
                                        options={endYearOptions}
                                        onChange={(selected) => {
                                            setEndYearFilter(
                                                selected.map((selectedItem: { id: string; name: string }) =>
                                                    parseInt(selectedItem.id)
                                                )
                                            );
                                        }}
                                        label={"End Year"}
                                        placeholder={"End Year"}
                                        sx={{ width: "100%", marginTop: "10px" }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"}>
                                <Button
                                    className={classes.clearButton}
                                    variant={"text"}
                                    onClick={() => {
                                        setCodeFilter("");
                                        setEndYearFilter([]);
                                        setTeamFilter("");
                                        setCountriesFilter([]);
                                        setRegionFilter([]);
                                        setRoFocalPointFilter([]);
                                        setCoFocalPointFilter([]);
                                        setFundingSourceFilter([]);
                                        setHrpPdrhFilter("");
                                        setImplementationPartnerFilter([]);
                                        setPrincipalInvestigatorFilter([]);
                                        setSrhFocalPointFilter([]);
                                        setCosponsorFilter("" as any);
                                    }}>
                                    Clear Filters
                                </Button>
                            </Grid>
                        </>
                    )}
                    <Grid container sx={{ margin: "10px 0" }}>
                        <Button sx={{ margin: "10px 10px 10px 0" }} variant="contained" onClick={getInnerCountriesCSV}>
                            Export Countries
                        </Button>
                        <Button sx={{ margin: "10px 10px 10px 0" }} variant="contained" onClick={getInnerAgenciesCSV}>
                            Export Agencies
                        </Button>
                        <Button
                            sx={{ margin: "10px 10px 10px 0" }}
                            variant="contained"
                            onClick={getInnerAgenciesRowCSV}>
                            Export Agencies by Rows
                        </Button>
                        <Button
                            sx={{ margin: "10px 10px 10px 0" }}
                            variant="contained"
                            onClick={getInnerCountriesRowCSV}>
                            Export Countries by Rows
                        </Button>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={40}>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.Code);
                                                }}>
                                                {orderColumn === Column.Code ? getOrderIcon() : defaultIcon}
                                            </Box>
                                            ID
                                        </Box>
                                    </TableCell>
                                    <TableCell width={60}>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.Team);
                                                }}>
                                                {orderColumn === Column.Team ? getOrderIcon() : defaultIcon}
                                            </Box>
                                            Team
                                        </Box>
                                    </TableCell>
                                    <TableCell width={355}>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.Title);
                                                }}>
                                                {orderColumn === Column.Title ? getOrderIcon() : defaultIcon}
                                            </Box>
                                            Title
                                        </Box>
                                    </TableCell>
                                    <TableCell width={120}>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.Countries);
                                                }}>
                                                {orderColumn === Column.Countries ? getOrderIcon() : defaultIcon}
                                            </Box>
                                            Countries
                                        </Box>
                                    </TableCell>
                                    <TableCell width={150}>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.ShrFocalPoint);
                                                }}>
                                                {orderColumn === Column.ShrFocalPoint ? getOrderIcon() : defaultIcon}
                                            </Box>
                                            SRH Focal Point
                                        </Box>
                                    </TableCell>
                                    <TableCell width={90}>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.EndDate);
                                                }}>
                                                {orderColumn === Column.EndDate ? getOrderIcon() : defaultIcon}
                                            </Box>
                                            End Date
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box className={classes.centerCell}>
                                            <Box
                                                className={classes.sortIcons}
                                                onClick={() => {
                                                    handleOrderDirection(Column.HasCosponsorEngagement);
                                                }}>
                                                {orderColumn === Column.HasCosponsorEngagement
                                                    ? getOrderIcon()
                                                    : defaultIcon}
                                            </Box>
                                            Cosponsor
                                        </Box>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageContent.map((project) => (
                                    <TableRow
                                        key={project.title}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {project.code}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {project.team}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {project.title}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {project.countryItems.map((countryItem) => countryItem.country).join(", ")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {fullNameOrEmpty(project.shrFocalPoint)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {formatFirebaseTimestamp(project.endDate)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {project.hasCosponsorEngagement ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Link to={`${NavigationRoute.ProjectDetails}/${project.id}`}>View</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container justifyContent={"center"} className={classes.pagination}>
                        <Pagination
                            count={Math.ceil(projects.length / pageSize)}
                            onChange={(event, value) => {
                                setPage(value);
                                setPageContent(projects.slice((value - 1) * pageSize, value * pageSize));
                            }}
                            page={page}
                            color="primary"
                        />
                    </Grid>
                </Grid>
            </Container>
        </StyledDashboard>
    );
};

export default withUserBoundary(Dashboard);
