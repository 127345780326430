import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const MultipleSelectCheckmarks = (props: {
    value?: any;
    options: { id: string; name: string }[];
    onChange: (value: any) => void;
    placeholder?: string;
    label?: string;
    sx?: any;
}) => {
    const handleChange = (_event: any, value: any) => {
        props.onChange(value);
    };

    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={props.options}
            disableCloseOnSelect
            onChange={handleChange}
            limitTags={1}
            value={props.value}
            isOptionEqualToValue={(option: any, value: any) => {
                return option.id === value.id;
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </li>
            )}
            sx={props.sx || { width: 500 }}
            renderInput={(params) => <TextField {...params} label={props.label} placeholder={props.placeholder} />}
        />
    );
};
