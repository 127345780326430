import { prefixClasses } from "../common/Theme/utils";
import { Grid, styled } from "@mui/material";

const classes = {
    root: "root",
    navBar: "navBar",
};

prefixClasses("NavBar", classes);

export const StyledNavBar = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {},
    [`& .${classes.navBar}`]: {
        paddingLeft: "50px",
        gridGap: "35px",
    },
}));

export const navBarClasses = classes;
