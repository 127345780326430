import React, { useCallback, useEffect, useState } from "react";
import { projectDetailsClasses as classes, StyledProjectDetails } from "./StyledProjectDetails";
import { Box, Button, Container, Divider, Grid, Modal, Typography } from "@mui/material";
import { doc, getDoc, getFirestore, deleteDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { IProject } from "../Dashboard/schema";
import { NavBar } from "../common/NavBar";
import { formatFirebaseTimestamp, timestampToDate, fullNameOrEmpty } from "../common/utils";
import { CreateProject } from "../Dashboard/CreateProject";
import { NavigationRoute } from "../common/NavigationRoute";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const style = {
    position: "absolute" as "absolute",
    overflowY: "scroll",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 340,
    height: 120,
    bgcolor: "background.paper",
    p: 5,
};

export const ProjectDetails = () => {
    const { id } = useParams();
    const [project, setProject] = useState<IProject>(null!);
    const [openDelete, setOpenDelete] = useState(false);
    const [toggleStyleCategories, setToggleStyleCategories] = useState<boolean[]>([false]);
    const handleToggleStyledCategories = (currentIndex: number) => {
        let newValues = [...toggleStyleCategories];
        newValues[currentIndex] = !newValues[currentIndex];
        setToggleStyleCategories(newValues);
    };
    const navigate = useNavigate();

    const fetchProject = useCallback(async () => {
        const docRef = doc(getFirestore(), "projects", id!);
        const querySnapshot = await getDoc(docRef);
        const newProject = querySnapshot.data();
        setProject({
            ...(newProject as any),
            id: querySnapshot.id,
        });
    }, [id]);

    useEffect(() => {
        fetchProject();
    }, [fetchProject]);

    const handleDelete = () => {
        deleteDoc(doc(getFirestore(), "projects", id!))
            .then(() => {
                setOpenDelete(false);
                navigate(NavigationRoute.Dashboard);
            })
            .catch((error) => {
                console.error("Error removing document: ", error);
            });
    };

    if (!project) {
        return null;
    }

    return (
        <StyledProjectDetails>
            <Container fixed className={classes.root}>
                <NavBar />
                <Grid className={classes.mainInfo} container md={10} lg={10}>
                    <Grid item md={9} lg={9} className={classes.titleWrapper}>
                        <Typography className={classes.title}>{project?.title}</Typography>
                    </Grid>
                    <Grid item md={3} lg={3} className={classes.buttons}>
                        <Grid item md={5} lg={5}>
                            {project && (
                                <CreateProject
                                    projectValues={{
                                        ...project,
                                        endDate: timestampToDate(project.endDate),
                                    }}
                                    onClose={() => {
                                        fetchProject();
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item md={5} lg={5}>
                            <Button variant={"contained"} color={"error"} onClick={() => setOpenDelete(true)}>
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                    <Modal
                        open={openDelete}
                        onClose={() => setOpenDelete(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Are you sure you want to delete this project?
                            </Typography>
                            <Button
                                variant={"contained"}
                                color={"error"}
                                sx={{ marginRight: 1 }}
                                onClick={handleDelete}>
                                {" "}
                                Yes
                            </Button>
                            <Button variant={"outlined"} onClick={() => setOpenDelete(false)}>
                                No
                            </Button>
                        </Box>
                    </Modal>
                </Grid>
                <Grid className={classes.mainGrid} container md={10} lg={10}>
                    <Grid item md={1} lg={1}>
                        <Typography className={classes.mainGridLabel}>ID</Typography>
                        <Typography className={classes.mainGridValue}>{project?.code}</Typography>
                    </Grid>
                    <Grid item md={1} lg={1}>
                        <Typography className={classes.mainGridLabel}>Team</Typography>
                        <Typography className={classes.mainGridValue}>{project?.team}</Typography>
                    </Grid>
                    <Grid item md={1.5} lg={1.5}>
                        <Typography className={classes.mainGridLabel}>End date</Typography>
                        <Typography className={classes.mainGridValue}>
                            {formatFirebaseTimestamp(project.endDate)}
                        </Typography>
                    </Grid>
                    <Grid item md={1.5} lg={1.5}>
                        <Typography className={classes.mainGridLabel}>HRP/PDRH</Typography>
                        <Typography className={classes.mainGridValue}>{project?.hrpPdrh}</Typography>
                    </Grid>
                    <Grid item md={2} lg={2}>
                        <Typography className={classes.mainGridLabel}>SRH Focal Point:</Typography>
                        <Typography className={classes.mainGridValue}>
                            {fullNameOrEmpty(project?.shrFocalPoint)}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Divider className={classes.mainDivider} />
            <Container className={classes.projectInfo} fixed>
                <Grid container className={classes.category} md={10} lg={10}>
                    <Typography className={classes.categoryLabel}>Description:</Typography>
                </Grid>
                <Grid container md={10} lg={10}>
                    <Typography className={classes.description}>{project?.description}</Typography>
                </Grid>
                <Divider className={classes.cosponsorDivider} />
                <Grid container className={classes.categoryRow}>
                    <Grid item className={classes.category} md={4} lg={4}>
                        <Typography className={classes.categoryLabel}>Cosponsor engagement:</Typography>
                    </Grid>
                    <Grid item md={4} lg={5}>
                        <Typography className={classes.categoryValue}>
                            {project?.hasCosponsorEngagement ? "Yes" : "No"}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.cosponsorDivider} />
                {project?.hasCosponsorEngagement && (
                    <>
                        <Grid container className={classes.categoryRow}>
                            <Grid item className={classes.category} md={4} lg={4}>
                                <Typography className={classes.categoryLabel}>Scope:</Typography>
                            </Grid>
                            <Grid item md={4} lg={5}>
                                <Typography className={classes.categoryValue}>{project?.scope}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.categoryRow}>
                            <Grid item className={classes.category} md={4} lg={4}>
                                <Typography className={classes.categoryLabel}>Status of Engagement:</Typography>
                            </Grid>
                            <Grid item md={4} lg={5}>
                                <Typography className={classes.categoryValue}>{project?.statusOfEngagement}</Typography>
                            </Grid>
                        </Grid>
                    </>
                )}

                {project?.agencies.map(
                    (agencyItem) =>
                        project?.hasCosponsorEngagement && (
                            <Container className={classes.agencyWrapper}>
                                <Grid container>
                                    <Grid item className={classes.agency} md={4} lg={4}>
                                        <Typography className={classes.agencyLabel}>Agency:</Typography>
                                    </Grid>
                                    <Grid item md={4} lg={5}>
                                        <Typography className={classes.agencyValue}>{agencyItem.agency}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.categoryRow}>
                                    <Grid item className={classes.category} md={4} lg={4}>
                                        <Typography className={classes.categoryLabel}>
                                            Primary Cosponsor Contact:
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4} lg={5}>
                                        <Typography className={classes.categoryValue}>
                                            {fullNameOrEmpty(agencyItem.primaryCosponsorContact)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        )
                )}
                {project?.scope !== "International" &&
                    project?.countryItems.map((countryItem, id: number) => (
                        <Grid key={id} className={classes.countryWrapper}>
                            <Grid container>
                                <Button
                                    onClick={() => {
                                        handleToggleStyledCategories(id);
                                    }}>
                                    {toggleStyleCategories[id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Button>
                                <Grid item className={classes.country} md={4} lg={4}>
                                    <Typography className={classes.countryLabel}>Country:</Typography>
                                </Grid>
                                <Grid item md={4} lg={5}>
                                    <Typography className={classes.countryValue}>{countryItem.country}</Typography>
                                </Grid>
                            </Grid>
                            {toggleStyleCategories[id] && (
                                <>
                                    <Grid className={classes.categoryRow} container>
                                        <Grid item className={classes.category} md={4} lg={4}>
                                            <Typography className={classes.categoryLabel}>Region:</Typography>
                                        </Grid>
                                        <Grid item md={4} lg={5}>
                                            <Typography className={classes.categoryValue}>
                                                {countryItem.region}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.categoryRow} container>
                                        <Grid item className={classes.category} md={4} lg={4}>
                                            <Typography className={classes.categoryLabel}>RO Focal Point:</Typography>
                                        </Grid>
                                        <Grid item md={4} lg={5}>
                                            <Typography className={classes.categoryValue}>
                                                {fullNameOrEmpty(countryItem.roFocalPoint)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.categoryRow} container>
                                        <Grid item className={classes.category} md={4} lg={4}>
                                            <Typography className={classes.categoryLabel}>CO Focal Point:</Typography>
                                        </Grid>
                                        <Grid item md={4} lg={5}>
                                            <Typography className={classes.categoryValue}>
                                                {fullNameOrEmpty(countryItem.coFocalPoint)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.categoryRow} container>
                                        <Grid item className={classes.category} md={4} lg={4}>
                                            <Typography className={classes.categoryLabel}>
                                                Implementation partner:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} lg={5}>
                                            <Typography className={classes.categoryValue}>
                                                {countryItem.implementationPartner}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.categoryRow} container>
                                        <Grid item className={classes.category} md={4} lg={4}>
                                            <Typography className={classes.categoryLabel}>
                                                Principal investigator:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} lg={5}>
                                            <Typography className={classes.categoryValue}>
                                                {fullNameOrEmpty(countryItem.pricipalInvestigator)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.categoryRow} container>
                                        <Grid item className={classes.category} md={4} lg={4}>
                                            <Typography className={classes.categoryLabel}>Funding Sources:</Typography>
                                        </Grid>
                                        <Grid item md={4} lg={5}>
                                            <Typography className={classes.categoryValue}>
                                                {countryItem.fundingSource}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            <Divider className={classes.countryDivider} />
                        </Grid>
                    ))}
            </Container>
        </StyledProjectDetails>
    );
};
